export const TAB_CODES = {
    RECORD_VIEW: 'HDR',
    WORKORDERS: 'EVT',
    COMMENTS: 'CMT',
    CLOSING_CODES: 'CLO',
    PARTS: 'XAP',
    PARTS_ASSOCIATED: 'PAS',
    EQUIPMENT_GRAPH_ASSETS: 'UT1',
    EQUIPMENT_GRAPH_POSITIONS: 'UT1',
    EQUIPMENT_GRAPH_SYSTEMS: 'UT1',
    STOCK: 'BIS', 
    WHERE_USED: 'EPA',
    ACTIVITIES: 'ACT',
    CHECKLIST: 'ACK',
    PART_USAGE: 'PAR',
    METER_READINGS: 'REA',
    EQUIPMENT_TAB_WO_SCREEN: 'MEC',
    CHILD_WO: 'CWO',
    EDMS_DOCUMENTS_ASSETS: 'UT2',
    EDMS_DOCUMENTS_POSITIONS: 'UT2',
    EDMS_DOCUMENTS_SYSTEMS: 'UT2',
    EDMS_DOCUMENTS_PARTS: 'UT1',
    EDMS_DOCUMENTS_WORK_ORDERS: 'UT5',
    EDMS_DOCUMENTS_LOCATIONS: 'UT1',
    BOOK_LABOR: 'BOO',
    ADDITIONAL_COSTS: 'ACO'
};

export const TAB_CODES_ASSETS = [
    TAB_CODES.RECORD_VIEW,
    TAB_CODES.WORKORDERS,
    TAB_CODES.COMMENTS,
    TAB_CODES.PARTS_ASSOCIATED,
    TAB_CODES.EDMS_DOCUMENTS_ASSETS,
    TAB_CODES.EQUIPMENT_GRAPH_ASSETS];

export const TAB_CODES_SYSTEMS = [
    TAB_CODES.RECORD_VIEW,
    TAB_CODES.WORKORDERS,
    TAB_CODES.COMMENTS,
    TAB_CODES.PARTS_ASSOCIATED,
    TAB_CODES.EDMS_DOCUMENTS_SYSTEMS,
    TAB_CODES.EQUIPMENT_GRAPH_SYSTEMS
];

export const TAB_CODES_POSITIONS = [
    TAB_CODES.RECORD_VIEW,
    TAB_CODES.WORKORDERS,
    TAB_CODES.COMMENTS,
    TAB_CODES.PARTS_ASSOCIATED,
    TAB_CODES.EDMS_DOCUMENTS_POSITIONS,
    TAB_CODES.EQUIPMENT_GRAPH_POSITIONS
];

export const TAB_CODES_LOCATIONS = [
    TAB_CODES.RECORD_VIEW,
    TAB_CODES.WORKORDERS,
    TAB_CODES.EDMS_DOCUMENTS_LOCATIONS,
    TAB_CODES.COMMENTS
];

export const TAB_CODES_WORK_ORDERS = [
    TAB_CODES.RECORD_VIEW,
    TAB_CODES.PART_USAGE,
    TAB_CODES.CHILD_WO,
    TAB_CODES.EDMS_DOCUMENTS_WORK_ORDERS,
    TAB_CODES.COMMENTS,
    TAB_CODES.ACTIVITIES,
    TAB_CODES.CHECKLIST,
    TAB_CODES.METER_READINGS,
    TAB_CODES.EQUIPMENT_TAB_WO_SCREEN,
    TAB_CODES.BOOK_LABOR,
    TAB_CODES.CLOSING_CODES,
    TAB_CODES.ADDITIONAL_COSTS
];

export const TAB_CODES_PARTS = [
    TAB_CODES.RECORD_VIEW,
    TAB_CODES.WHERE_USED,
    TAB_CODES.COMMENTS,
    TAB_CODES.STOCK,
    TAB_CODES.EDMS_DOCUMENTS_PARTS
];